import React from "react";
import styled from "styled-components";

import AnimatedBackground from "../components/AnimatedBackground";
import cart from '../getcustomer-media/cart.gif'
import iphone from '../getcustomer-media/iphone.png'

const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: center;
    z-index: 1;

    .left-content {
        flex: 1;

        button {
            padding: 10px 20px;
            background-color: #3a3aff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 20px;
            font-size: 2rem;
        }
    }

    .right-content {
        position: relative;
        width: 200px;
        height: auto;

        img {
            &:first-child {
                position: absolute;
                top: 10%;
                left: 10%;
                width: 80%;
                height: 80%;
            }

            &:last-child {
                width: 100%;
                height: auto;
            }
        }
    }
`;

const StyledNav = styled.nav`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #3a3aff;
    color: white;

    h1, h2 {
        margin-right: 1rem;
    }
`;

export default class Homepage extends React.Component {
  render() {
    return (
        <div style={{ width: '100%', height: '100vh', overflow: 'hidden', position: 'relative' }}>
        <AnimatedBackground />
        <StyledNav>
            <h1>GetCustomer AI</h1>
        </StyledNav>
        <StyledHeader>
            <div className='left-content'>
                <h1>Empower your emails with dynamic content for 3X conversion rate.</h1>

                <button>Coming Soon</button>
            </div>
             {/* <div className='right-content'>
                <img src={cart} alt='cart' />
                <img src={iphone} alt='iphone' style={{ width: '200px' }} />
            </div> */}
        </StyledHeader>

        </div>
    );
  }
}